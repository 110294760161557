import { ReactElement, ReactNode } from 'react';

import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme, Typography } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';

import GHBackground from './GHBackground';
import GHContent from './GHContent';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.background.default,
      position: 'relative',
    },
    container: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(8),
    },
    imageContainer: {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        paddingBottom: theme.spacing(2),
      },
    },
    image: {
      height: '100%',
      width: '100%',
      borderRadius: theme.shape.borderRadius,
    },
    textContainerLeft: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(4),
      },
    },
    textContainerRight: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(4),
      },
    },
    list: {
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'column',
      paddingInlineStart: 0,
    },

    listItem: {
      display: 'flex',
      alignItems: 'baseline',
    },
    listItemText: {
      paddingLeft: theme.spacing(2),
    },
  };
});

export interface Props {
  backgroundImageSide: 'left' | 'right';
  mainMediaSide: 'left' | 'right';
  mediaSrc: string;
  mediaType?: 'image' | 'video';
  mediaClass?: string;
  headerText: ReactNode;
  preListText: ReactNode;
  listText: ReactNode[] | null;
  listIcon: IconDefinition | null;
  postListChildren?: ReactElement;
}

const GHSection = ({
  backgroundImageSide,
  mainMediaSide,
  mediaSrc,
  mediaType = 'image',
  mediaClass,
  headerText,
  preListText,
  listText,
  listIcon,
  postListChildren,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GHBackground side={backgroundImageSide} />
      <GHContent>
        <Grid
          container
          className={classes.container}
          direction={mainMediaSide === 'left' ? 'row' : 'row-reverse'}
          alignItems="center"
        >
          <Grid item sm={5} xs={12} className={classes.imageContainer}>
            {mediaType === 'video' ? (
              <video
                autoPlay
                muted
                loop
                webkit-playsinline="true"
                playsInline
                className={
                  mediaClass
                    ? classNames(mediaClass, classes.image)
                    : classes.image
                }
              >
                <source src={mediaSrc} type="video/mp4" />
              </video>
            ) : (
              <img
                src={mediaSrc}
                className={
                  mediaClass
                    ? classNames(classes.image, mediaClass)
                    : classes.image
                }
              ></img>
            )}
          </Grid>
          <Grid
            item
            sm={7}
            xs={12}
            className={
              mainMediaSide === 'left'
                ? classes.textContainerRight
                : classes.textContainerLeft
            }
          >
            {headerText}
            {preListText}
            {listText && (
              <ul className={classes.list}>
                {listText.map((text, index) => {
                  return (
                    <li key={index} className={classes.listItem}>
                      {listIcon && (
                        <FontAwesomeIcon icon={listIcon}></FontAwesomeIcon>
                      )}
                      <Typography
                        variant="body2"
                        className={classes.listItemText}
                      >
                        {text}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
            )}
            {postListChildren}
          </Grid>
        </Grid>
      </GHContent>
    </div>
  );
};

export default GHSection;
