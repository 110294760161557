import { ReactNode } from 'react';

import { makeStyles, Theme } from '@material-ui/core';

import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      paddingTop: '150px'
    }
  };
});

export interface Props {
  children: ReactNode;
  className?: string;
}

const GHRoot = ({ className, children }: Props) => {
  const classes = useStyles();

  return <div className={classNames(className, classes.root)}>{children}</div>;
};

export default GHRoot;
