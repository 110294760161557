import { makeStyles, Theme } from '@material-ui/core/styles';

import { ReactNode } from 'react';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.background.default,
      width: '100%',
      borderTop: '8px solid #3e5c76',
      boxShadow: '0px 12px 18px 0px rgba(0,0,0,0.1)',
      borderRadius: theme.shape.borderRadius
    },
    inner: {
      padding: theme.spacing(2)
    }
  };
});

export interface GHButtonProps {
  children: ReactNode;
  className?: string;
}

const GHCard = ({ children, className }: GHButtonProps) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.inner}>{children}</div>
    </div>
  );
};

export default GHCard;
