import Jumbotron from '../components/jumbotron';

import ShowcaseGallery from '../components/showcaseGallery';

const Index = () => {
  return (
    <>
      <Jumbotron></Jumbotron>
      <ShowcaseGallery></ShowcaseGallery>
    </>
  );
};

export default Index;
