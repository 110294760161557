import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    backgroundLeft: {
      backgroundImage: 'url("https://cdn.governorhub.com/images/shapes.png")',
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      width: '100%',
      opacity: 0.6,
      position: 'absolute',
      backgroundPositionX: 'left',
      backgroundPositionY: 'bottom',
      top: 0,
      left: 0,
    },
    backgroundRight: {
      backgroundImage: 'url("https://cdn.governorhub.com/images/shapes.png")',
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      width: '100%',
      opacity: 0.6,
      position: 'absolute',
      backgroundPositionX: 'right',
      backgroundPositionY: 'bottom',
      top: 0,
      right: 0,
    },
  };
});

export interface Props {
  side: 'left' | 'right';
}

const GHBackground = ({ side }: Props) => {
  const classes = useStyles();

  const backgroundClass =
    side === 'left' ? classes.backgroundLeft : classes.backgroundRight;

  return <div className={backgroundClass}></div>;
};

export default GHBackground;
