import React, { useState } from 'react';

import VizSensor from 'react-visibility-sensor';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Theme,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';

import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';

import classNames from 'classnames';

import GHBackground from '../common/GHBackground';

import GHSection from '../common/GHSection';

import GHButton from '../common/GHButton';
import GHContent from '../common/GHContent';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.background.default,
      position: 'relative'
    },
    content: {
      maxWidth: '900px'
    },
    videoContent: {
      maxWidth: '900px',
      paddingBottom: theme.spacing(4)
    },
    paddingTop: {
      paddingTop: theme.spacing(10)
    },
    bigMarginTop: {
      marginTop: theme.spacing(5)
    },
    image: {
      height: '100%',
      width: '100%',
      borderRadius: theme.shape.borderRadius
    },
    paragraph: {
      textAlign: 'center'
    },
    slide: {
      display: 'flex',
      justifySelf: 'center'
    },
    list: {
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'column',
      paddingInlineStart: 0,
      alignContent: 'center'
    },
    listItem: {
      display: 'flex',
      alignItems: 'baseline'
    },
    listItemText: {
      paddingLeft: theme.spacing(2),
      '&:hover': {
        cursor: 'pointer'
      }
    },
    highlight: {
      fontWeight: theme.typography.fontWeightBold
    },
    lowOpacity: {
      opacity: 0.3
    },
    mainVideo: {
      paddingBottom: theme.spacing(2)
    },
    promoButton: {
      marginTop: theme.spacing(2)
    }
  };
});

const ghItem = {
  backgroundImageSide: 'left',
  mediaSrc: 'https://cdn.governorhub.com/images/ghbenefits1_2.mp4',
  mediaType: 'video',
  mainMediaSide: 'right',
  headerText: 'GovernorHub',
  preListText: 'Securely manage the work of your board',
  listText: [
    'Get organised with a shared calendar and document library',
    'Communicate better with our interactive noticeboard',
    'Provide an instant body of evidence for when you need it most'
  ],
  listIcon: faArrowAltCircleRight
};

const keyItem = {
  backgroundImageSide: 'right',
  mediaSrc: 'https://cdn.governorhub.com/images/ghbenefits3_4.mp4',
  mediaType: 'video',
  mainMediaSide: 'left',
  headerText: 'Upgrade to GovernorHub Knowledge',
  preListText:
    'Get unlimited access to expert advice, guidance and training, when you need it – all in one place.',
  listText: [
    'Be more effective, with access to hundreds of practical resources and tips',
    'Stay compliant and keep learning, with our online training courses',
    'Keep up to date with our weekly email, packed full of timely guidance and tailored information for your role and setting'
  ],
  listIcon: faArrowAltCircleRight
};

const ShowcaseGallery = () => {
  const classes = useStyles();
  const theme = useTheme();

  // set due to bug in mui causing it to need to be 'xs' a not 'sm' as expected
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [promoVisible, setPromoVisible] = useState(false);
  const [ghVisible, setGhVisible] = useState(false);
  const [ksgVisible, setKsgVisible] = useState(false);

  return (
    <div>
      <div className={classes.root}>
        <GHContent className={classes.content}>
          <Grid container justifyContent="center">
            <Grid item xs={12} className={classes.paragraph}>
              <Typography variant={'body1'}>
                Designed by governors, for governors who want to feel confident
                in their role and the work of their board(s). Our governance
                solutions will help you work better, develop your skills and
                evidence your efforts.
              </Typography>
            </Grid>
          </Grid>
        </GHContent>

        <VizSensor
          onChange={isVisible => {
            setPromoVisible(isVisible);
          }}
          partialVisibility={isMobile}
        >
          <GHContent
            className={classNames(classes.content, classes.paddingTop, {
              [classes.lowOpacity]: !promoVisible
            })}
          >
            <GHBackground side={'left'} />
            <GHContent>
              <Grid container justifyContent="center">
                <Grid item xs={10} className={classes.paragraph}>
                  <Typography variant={'h2'}>Join a demo!</Typography>
                  <Typography variant={'body1'}>
                    Find out how GovernorHub helps boards to work together
                    better.
                    <br />
                    Join a{' '}
                    <Typography
                      display="inline"
                      color="secondary"
                      className={classes.highlight}
                    >
                      30 minute taster
                    </Typography>{' '}
                    and see how GovernorHub can help your board to manage
                    governance more effectively.
                    <br />
                    <br />
                    You&apos;ll soon discover why more than two-thirds of
                    schools in England use GovernorHub to support great
                    governance.
                  </Typography>
                  <GHButton
                    href="https://webinars.governorhub.com/ghb-demo"
                    className={classes.promoButton}
                  >
                    Learn more
                  </GHButton>
                </Grid>
              </Grid>
            </GHContent>
          </GHContent>
        </VizSensor>

        <VizSensor
          onChange={isVisible => {
            setGhVisible(isVisible);
          }}
          partialVisibility={isMobile}
        >
          <GHContent
            className={classNames(classes.content, classes.paddingTop, {
              [classes.lowOpacity]: !ghVisible
            })}
          >
            <GHSection
              backgroundImageSide={'left'}
              mainMediaSide={'right'}
              mediaSrc={ghItem.mediaSrc}
              mediaType={'video'}
              headerText={
                <Typography variant="h2">{ghItem.headerText}</Typography>
              }
              preListText={
                <Typography variant="body1">{ghItem.preListText}</Typography>
              }
              listText={ghItem.listText}
              listIcon={ghItem.listIcon}
              postListChildren={
                <GHButton href="/features" variant="inverse-outlined">
                  Find out more
                </GHButton>
              }
            />
          </GHContent>
        </VizSensor>

        <VizSensor
          onChange={isVisible => {
            setKsgVisible(isVisible);
          }}
          partialVisibility={isMobile}
        >
          <GHContent
            className={classNames(classes.content, {
              [classes.paddingTop]: isMobile,
              [classes.lowOpacity]: !ksgVisible
            })}
          >
            <GHSection
              backgroundImageSide={'right'}
              mainMediaSide={'left'}
              mediaSrc={keyItem.mediaSrc}
              mediaType={'video'}
              headerText={
                <Typography variant="h2">{keyItem.headerText}</Typography>
              }
              preListText={
                <Typography variant="body1">{keyItem.preListText}</Typography>
              }
              listText={keyItem.listText}
              listIcon={keyItem.listIcon}
              postListChildren={
                <GHButton href="/ksg-features" variant="inverse-outlined">
                  Find out more
                </GHButton>
              }
            />
          </GHContent>
        </VizSensor>
        <GHContent className={classes.videoContent}>
          <Grid container className={classes.mainVideo}>
            <video
              width={'100%'}
              poster="https://cdn.governorhub.com/images/YT_thumbnail.png"
              controls
            >
              <source
                src="https://cdn.governorhub.com/images/FULL_GH+animation_Who+We+Are+v3+HB.mp4"
                type="video/mp4"
              />
            </video>
          </Grid>
        </GHContent>
      </div>
    </div>
  );
};

export default ShowcaseGallery;
