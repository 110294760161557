import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme, Typography } from '@material-ui/core';

import GHButton from '../common/GHButton';
import GHBackground from '../common/GHBackground';
import GHCard from '../common/GHCard';
import GHContent from '../common/GHContent';
import GHRoot from '../common/GHRoot';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(6)
    },
    textContainer: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(2)
      }
    }
  };
});

// names as such because of the old bootstrap, call to action element from back in the day
const Jumbotron = () => {
  const classes = useStyles();

  return (
    <GHRoot>
      <GHBackground side="right"></GHBackground>
      <GHContent>
        <Grid container className={classes.container}>
          <Grid
            item
            md={12}
            container
            className={classes.textContainer}
            justifyContent="center"
          >
            <GHCard>
              <Typography variant="h1">Supporting great governance</Typography>
              <Typography variant="body1" gutterBottom>
                Helping more than 100,000 governors, trustees and clerks focus
                on what matters:{' '}
                <i>
                  <b>making a difference.</b>
                </i>
              </Typography>
              <Grid item container spacing={1} justifyContent="center">
                <Grid item>
                  <GHButton
                    onClick={() =>
                      (document.location.href = 'https://app.governorhub.com')
                    }
                    variant="inverse-outlined"
                  >
                    Sign in
                  </GHButton>
                </Grid>
                <Grid item>
                  <GHButton href="/signup" variant="contained">
                    Try For Free
                  </GHButton>
                </Grid>
              </Grid>
            </GHCard>
          </Grid>
        </Grid>
      </GHContent>
    </GHRoot>
  );
};

export default Jumbotron;
